var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":"Contatos Whatsapp"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-button',{staticClass:"mr-2",attrs:{"color":"primary","label":"Importar .CSV<","outlined":""},on:{"click":function($event){_vm.csvDialog = true}}})],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"auto"}},[_c('mf-button',{staticClass:"mr-2",attrs:{"color":"primary","label":"Deletar .CSV","outlined":""},on:{"click":function($event){_vm.deleteCsvDialog = true}}})],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"color":"primary","label":"Painel de Ações"},on:{"click":_vm.actionsPanel}})],1)],1),_c('v-row',[_c('v-col',[_c('mf-card-container',{attrs:{"placeholder":"Busca por telefone","use-search":"","title":"Contatos","description":'Listagem dos contatos que estão vinculados a conta ' + _vm.name},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('data-table-selectable',{attrs:{"search":_vm.search,"headers":_vm.headers,"query":_vm.query,"queryName":"adminWppUsers","account-id":_vm.accountId,"query-params":_vm.queryParams,"selected":_vm.numbers,"itemKey":_vm.itemKey},on:{"update:selected":function($event){_vm.numbers=$event}},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.number))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.created_at)))]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.updated_at)))]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openContactModal(item.number, item._id)}}},[_c('v-list-item-title',[_vm._v("Excluir")])],1)],1)],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-switch',{on:{"change":function($event){return _vm.changeActive($event, item._id)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)],1)]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',{staticClass:"headline justify-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account")]),_vm._v(" Contato")],1),_c('v-card-text',{staticClass:"text-center"},[_c('strong',[_vm._v("Número")]),_vm._v(": "+_vm._s(_vm.formatNumber(_vm.selected.number)))]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.closeModal}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"error","dark":""},on:{"click":function($event){return _vm.deleteNumber(_vm.selected.id)}}},[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" Excluir Contato ")],1)],1)],1)],1),_c('wpp-actions',{attrs:{"selected":_vm.numbers,"accountId":_vm.accountId},on:{"update:selected":function($event){_vm.numbers=$event},"close":_vm.closeActionsPanel},model:{value:(_vm.actionsDialog),callback:function ($$v) {_vm.actionsDialog=$$v},expression:"actionsDialog"}}),_c('import-csv',{attrs:{"account-id":_vm.accountId},on:{"close":_vm.closeImportCsv},model:{value:(_vm.csvDialog),callback:function ($$v) {_vm.csvDialog=$$v},expression:"csvDialog"}}),_c('delete-csv',{attrs:{"account-id":_vm.accountId},on:{"close":_vm.closeDeleteCsv},model:{value:(_vm.deleteCsvDialog),callback:function ($$v) {_vm.deleteCsvDialog=$$v},expression:"deleteCsvDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }